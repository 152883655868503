import * as React from "react";
import { HeadFC } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { DesktopLayoutBasicColumn } from "../../components/layout/desktop-layouts/desktop-layout-basic-column";
import {
  ProgramWindow,
  ProgramWindowPadding,
} from "../../components/gui/organisms/program-window/program-window";
import { PostContent } from "../../components/gui/atoms/post-content";
import styled from "styled-components";
import { DesktopId } from "../../app/desktops";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export const postTitle =
  "How to Compile MS-DOS Programs from your Windows/Mac/Linux desktop";

export const postBlurb = (
  <>
    <p>
      While there are plenty of tutorials on compiling programs for MS-DOS using
      an editor like Turbo C, nothing beats the convenience of a modern
      programming environment.
    </p>
    <p>
      With this simple setup, you can get the best of both worlds: native 16-bit
      compilation using Turbo C, combined with the power of your modern text
      editor.
    </p>
  </>
);

const StyledHeaderImageWrapper = styled.div`
  max-height: 50vh;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const PostMSDosCompilation = () => {
  return (
    <DesktopLayoutBasicColumn
      desktopId={DesktopId.POSTS_MSDOS}
      canWindowsRearrange={true}
    >
      <ProgramWindow
        windowId="msdos-post-body"
        title=" "
        applyContentPadding={false}
      >
        <StyledHeaderImageWrapper>
          <StaticImage
            src="../../images/dalle/art/floppy-computer-01.png"
            alt="Painting of floppy disks, compact discs, and an old computer monitor with its circuitry exposed, generated by DALLE-2"
          />
        </StyledHeaderImageWrapper>
        <ProgramWindowPadding>
          <PostContent>
            <h1 id="msdos-compilation">{postTitle}</h1>
            <h2>Overview</h2>
            <p>
              The setup for this is pretty robust, since we're going to sidestep
              the issues with trying to get 16-bit compilation working on a
              modern computer.
            </p>
            <p>We'll be able to:</p>
            <ol>
              <li>
                Edit our source code using whatever text editor we want (e.g. VS
                Code)
              </li>
              <li>
                Leverage DOSBox to <strong>automatically</strong> do the
                following:
                <ol>
                  <li>Mount the source code in MS-DOS</li>
                  <li>Compile the source code in Turbo C</li>
                  <li>Capture the output logs and check for errors</li>
                </ol>
              </li>
              <li>
                If compilation succeeds, then we can also run the program
                automatically.
              </li>
            </ol>
            <p>
              Check out an example template at{" "}
              <a
                href="https://github.com/Simon-Tang/msdos-game-example"
                target="_blank"
              >
                GitHub
              </a>
              .
            </p>

            <h2>Part 1: Installation</h2>
            <p>
              Start with installing{" "}
              <OutboundLink href="https://www.dosbox.com/" target="_blank">
                DOSBox
              </OutboundLink>
              . If it doesn't automatically get added to your <code>$PATH</code>{" "}
              variable, then follow the instructions for your OS to do this.
            </p>
            <p>
              You'll also need to download Turbo C (
              <a
                href="https://archive.org/details/msdos_borland_turbo_c_2.01"
                target="_blank"
              >
                official link
              </a>
              ) and extract it to an accessible folder on your computer (e.g.{" "}
              <code>~/turboc</code>). For inline assembly code, you'll need to
              download{" "}
              <a
                href="http://trimtab.ca/2010/tech/tasm-5-intel-8086-turbo-assembler-download/"
                target="_blank"
              >
                Turbo Assembler 5.0
              </a>{" "}
              - check the linked template above for an example integration.
            </p>

            <h2>Part 2: Project Structure</h2>
            <p>Create the following files:</p>
            <pre>
              {`
~/project
├── build.dosbox.conf
├── build.sh
├── run.sh
├── build
|   ├── .gitkeep  # optional - this file just keeps the folder present when git cloning
└── src
    ├── main.c
              `.trim()}
            </pre>

            <h3>
              <code>build.sh</code>
            </h3>
            <p>
              This script will be used to invoke DOSBox for compiling our code.
            </p>
            <pre>
              {`
#!/usr/bin/env bash

set -e

executable="./build/GAME.EXE"
buildlog="./build/TCC.LOG"

SDL_VIDEODRIVER=dummy dosbox -conf ./build.dosbox.conf -noconsole

if [ ! -f "$buildlog" ]
then
    echo 'Error: No build log found.'
    exit 4
fi

cat "$buildlog"

if grep --quiet 'error\|Error\|Undefined symbol' $buildlog
then
    echo 'Error(s) during compilation.'
    exit 5
elif [ ! -f "$executable" ]
then
    echo 'Error: No executable found.'
    exit 6
fi
                `.trim()}
            </pre>
            <p>
              On Linux and Windows, the <code>SDL_VIDEODRIVER=dummy</code> and{" "}
              <code>-noconsole</code> options intentionally hide the DOSBox
              window. (During the build step, we don't need to view the window,
              which only takes a few seconds at most.)
            </p>
            <p>
              We scan the contents of <code>./build/TCC.LOG</code> to
              automatically check for compilation errors.
            </p>
            <p>
              Later, we'll populate the <code>build.dosbox.conf</code> file
              which is referenced here.
            </p>

            <h3>
              <code>run.sh</code>
            </h3>
            <pre>
              {`
#!/usr/bin/env bash
dosbox ./build/GAME.EXE
                `.trim()}
            </pre>
            <p>
              This script is used for executing the compiled code. To
              automatically run after a successful build, the command{" "}
              <code>./build.sh &amp;&amp; ./run.sh</code> can achieve this.
            </p>
            <p>
              If your game requires specific DOSBox settings (e.g. CPU speed),
              then you can create a <code>run.dosbox.conf</code> file and update
              your <code>run.sh</code>:
            </p>
            <pre>
              {`
#!/usr/bin/env bash
dosbox -conf run.dosbox.conf ./build/GAME.EXE
                `.trim()}
            </pre>

            <h3>
              <code>build.dosbox.conf</code>
            </h3>
            <pre>
              {`
[autoexec]

MOUNT S: ./src
MOUNT T: ~/turboc
MOUNT O: ./build

DEL O:\TCC.LOG

T:
TCC -1 -ml -IT:\INCLUDE -IS:\ -LT:\LIB -eGAME.EXE -nO:\ S:\*.c > O:\TCC.LOG

EXIT
                `.trim()}
            </pre>
            <p>
              With this DOSBox config, we mount the folders containing our code,
              the Turbo C compiler, and build output. We then compile the code
              using Turbo C, store the output on our hard drive, and exit.
            </p>

            <p>A breakdown of these Turbo C arguments:</p>
            <ul>
              <li>
                <code>-1</code> - Target 80186/286{" "}
                <em>(Optional - targets 80386 when omitted)</em>
              </li>
              <li>
                <code>-ml</code> - Uses the{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Intel_Memory_Model#Memory_models"
                  target="_blank"
                >
                  Large memory model
                </a>
                , which we need to access VGA video memory
              </li>
              <li>
                <code>-IT:\INCLUDE</code> - Includes the Turbo C header files
                (such as <code>DOS.H</code>)
              </li>
              <li>
                <code>-IS:\</code> - Includes your own header files in{" "}
                <code>./src</code>
              </li>
              <li>
                <code>-LT:\LIB</code> - Includes Turbo C's own libraries
              </li>
              <li>
                <code>-eGAME.EXE</code> - Sets the compiled executable name
                (must be a 8.3 filename)
              </li>
              <li>
                <code>-nO:\</code> - Outputs the compiled file(s) to{" "}
                <code>O:</code>
                (a.k.a. <code>./build</code>)
              </li>
              <li>
                <code>S:\*.c</code> - Compile your C source files in{" "}
                <code>./src</code>
              </li>
              <li>
                <code>&gt; O:\TCC.LOG</code> - Redirects the output to
                <code>./build/TCC.LOG</code>
              </li>
            </ul>
            <h2>Part 3: Usage</h2>
            <p>
              If all is in working order, you should be able to compile and run
              an MS-DOS game that uses VGA graphics:
            </p>
            <ol>
              <li>
                Download this VGA color palette tester to your{" "}
                <code>./src</code> folder :{" "}
                <code>
                  <OutboundLink href="/posts/msdos-compilation/main.c">
                    main.c
                  </OutboundLink>
                </code>
              </li>
              <li>
                Test if everything works:{" "}
                <code>./build.sh &amp;&amp; ./run.sh</code>
              </li>
            </ol>
            <div style={{ textAlign: "center", margin: "2em 0" }}>
              <StaticImage
                src="../../images/posts/dosbox-test-01.png"
                alt="A screenshot of the compiled test program successfully running in DOSBox, with rows of VGA colours on screen."
              />
            </div>
            <p>
              Congratulations, if you've made it through all of these steps!
            </p>

            <h2>Part 4: Caveats</h2>
            <p>
              Since Turbo C was released in the 80s, these are a few things to
              remember to align with the older C spec:
            </p>
            <ul>
              <li>
                Comments must be block-style comments (<code>/* ... */</code>)
              </li>
              <li>Source files must use Windows line endings (CRLF)</li>
              <li>Variables must be declared at the start of scope blocks</li>
            </ul>
            <h3>4.1 - Inline Assembly</h3>
            <p>
              If you use inline assembly code, Turbo Assembler 5.0 won't support
              the <code>asm &#123; ... &#125;</code> block syntax:
            </p>
            <pre>
              {`
/* Doesn't work! */
void interrupt scan_keyboard(void)
{
    asm {
        cli
        ...
        sti
    }
}
                `.trim()}
            </pre>
            <p>
              Instead, use the <code>asm</code> keyword on each line:
            </p>
            <pre>
              {`
/* Works! */
void interrupt scan_keyboard(void)
{
    asm cli
    ...
    asm sti
}
                `.trim()}
            </pre>
          </PostContent>
        </ProgramWindowPadding>
      </ProgramWindow>
      {/* <div style={{ alignSelf: "center", maxWidth: 800 }}>
          <ProgramWindow title="About">
            <PostContent>
              TODO: about content?
            </PostContent>
          </ProgramWindow>
        </div> */}
    </DesktopLayoutBasicColumn>
  );
};

export default PostMSDosCompilation;

export const Head: HeadFC = () => <title>{postTitle} | simontang.dev</title>;
